import { defineComponent } from 'vue';
import { cloneDeep } from 'lodash';
import { roseNanoid, rules } from '@rose/common-ui';
export default defineComponent({
  props: {
    dashboard: {
      type: Object,
      "default": function _default() {
        var newDashboard = {
          id: roseNanoid(),
          name: '',
          layout: []
        };
        return newDashboard;
      }
    },
    createNewMode: {
      type: Boolean,
      "default": false
    }
  },
  data: function data() {
    return {
      editingDashboard: null,
      detailsEditValid: true,
      rules: rules
    };
  },
  computed: {},
  watch: {
    dashboard: {
      immediate: true,
      handler: function handler(dashboard) {
        this.editingDashboard = cloneDeep(dashboard);
      }
    }
  },
  methods: {
    saveEdit: function saveEdit() {
      if (!this.detailsEditValid) {
        return;
      }

      this.$emit('save', this.editingDashboard);
      this.editingDashboard = null;
    },
    deleteDashboard: function deleteDashboard() {
      this.$emit('delete', this.editingDashboard);
      this.editingDashboard = null;
    },
    close: function close() {
      this.$emit('close');
      this.editingDashboard = null;
    }
  }
});