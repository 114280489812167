/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./EditDashboardDetails.vue?vue&type=template&id=276b1d03&scoped=true&lang=pug&"
import script from "./EditDashboardDetails.vue?vue&type=script&lang=ts&"
export * from "./EditDashboardDetails.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "276b1d03",
  null
  
)

export default component.exports